import { Minimal, TaskCreationBody } from '@models/base';
import { Module } from '@pkg/config';
import {
  AcnButton,
  AcnCard,
  AutoForm,
  BreadcrumbItem,
  DropdownOptions,
  baseButton,
  baseScrollbar,
  fetchData,
  getModelValues,
  useFormModel,
  useTrans,
} from '@pkg/ui';
import { cn, showErrorNoti, showSuccessNoti } from '@pkg/utils';
import { useQuery } from '@tanstack/react-query';
import { cloneDeep, set } from 'lodash';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Toolbar } from '../../components';
import { getModule } from '../../helpers/utils';
import { TaskApi } from './api';
import { TaskCreationModel } from './create.model';

export const TaskCreateContainer = () => {
  const { t } = useTrans();
  const router = useRouter();
  const taskApi = TaskApi();

  const formModel = useFormModel(TaskCreationModel.model, {});

  const form = useForm<TaskCreationBody>({
    mode: 'all',
    defaultValues: getModelValues(TaskCreationModel.model),
  });

  const targetValue = form.watch('target') as Module;

  const { data } = useQuery<Minimal[]>({
    queryKey: ['task-parent', targetValue],
    queryFn: () => {
      if (targetValue === 'posts') {
        return fetchData({ endpoint: 'post-categories' }).then((rs) => {
          if (!rs.data) return [];
          return rs.data;
        });
      }
      return [];
    },
    enabled: !!targetValue && !!formModel.model,
  });

  useEffect(() => {
    if (data && formModel.model) {
      const model = cloneDeep(formModel.model);
      const options: DropdownOptions[] = data.map((r) => ({ label: r.name, value: r._id }));
      set(model, 'parent.options', options);
      formModel.setModel(model);
    }
  }, [data]);

  const breadcrumb: BreadcrumbItem[] = [
    { title: t('dashboard.title'), href: '/' },
    { title: t('task.title'), href: '/' + getModule(router) },
    { title: t('task.form.title') },
  ];

  const onCreate = async (data: TaskCreationBody) => {
    const res = await taskApi.create(data);
    if (!res.data) return showErrorNoti(res.error.message);
    showSuccessNoti(t('createSuccess'));
    router.push('/' + getModule(router));
  };

  return (
    <div className="space-y-3">
      <Toolbar items={breadcrumb} />
      <div className={cn('flex gap-3 h-[calc(100vh-175px)]', baseScrollbar)}>
        <FormProvider {...form}>
          <div className={cn('flex-1 space-y-3')}>
            <AcnCard>
              <AcnCard.Title>{t('details')}</AcnCard.Title>
              <AcnCard.Content className="min-h-[calc(100vh-221px)]">
                <div className="space-y-4">
                  <AutoForm
                    model={formModel.model}
                    excludeFields={['type', 'target', 'parent']}
                    setModel={formModel.setModel}
                  />
                </div>
              </AcnCard.Content>
            </AcnCard>
          </div>

          <div className="w-[400px] space-y-3">
            <AcnCard>
              <AcnCard.Title>{t('publish')}</AcnCard.Title>
              <AcnCard.Content className="space-x-4">
                <AcnButton className={cn(baseButton, '!bg-blue-500 !text-white')} onClick={form.handleSubmit(onCreate)}>
                  {t('createBtn')}
                </AcnButton>
              </AcnCard.Content>
            </AcnCard>

            <AcnCard>
              <AcnCard.Content className="space-y-4">
                <AutoForm model={formModel.model} fields={['type', 'target', 'parent']} setModel={formModel.setModel} />
              </AcnCard.Content>
            </AcnCard>
          </div>
        </FormProvider>
      </div>
    </div>
  );
};
