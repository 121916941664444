import { Module } from '@pkg/config';
import { TaskType } from '@pkg/constants';
import { TaskCreationFieldModel, targetOptions, taskTypeOptions } from '../../types/task';

type TaskCreationModelProps = {};

export const TaskCreationModel = {
  model: (props: TaskCreationModelProps = {}): TaskCreationFieldModel => {
    return {
      type: {
        label: 'task.form.fields.type.label',
        placeholder: 'task.form.fields.type.placeholder',
        inputType: 'Dropdown',
        name: 'type',
        options: taskTypeOptions,
        value: TaskType.create,
        rules: { required: 'task.form.fields.type.required' },
      },
      name: {
        label: 'task.form.fields.name.label',
        placeholder: 'task.form.fields.name.placeholder',
        inputType: 'Input',
        name: 'username',
        rules: { required: 'task.form.fields.name.required' },
      },
      target: {
        label: 'task.form.fields.target.label',
        placeholder: 'task.form.fields.target.placeholder',
        inputType: 'Dropdown',
        name: 'target',
        value: 'posts' as Module,
        options: targetOptions,
        rules: { required: 'task.form.fields.target.required' },
      },
      parent: {
        label: 'task.form.fields.parent.label',
        placeholder: 'task.form.fields.parent.placeholder',
        inputType: 'Dropdown',
        name: 'parent',
        options: [],
        rules: {},
      },
      sourceUrl: {
        label: 'task.form.fields.sourceUrl.label',
        placeholder: 'task.form.fields.sourceUrl.placeholder',
        inputType: 'Input',
        name: 'sourceUrl',
        rules: { required: 'task.form.fields.sourceUrl.required' },
      },
      listSelector: {
        label: 'task.form.fields.listSelector.label',
        placeholder: 'task.form.fields.listSelector.placeholder',
        inputType: 'Input',
        name: 'listSelector',
        rules: { required: 'task.form.fields.listSelector.required' },
      },
      itemSelector: {
        label: 'task.form.fields.itemSelector.label',
        placeholder: 'task.form.fields.itemSelector.placeholder',
        inputType: 'Input',
        name: 'itemSelector',
        rules: { required: 'task.form.fields.itemSelector.required' },
      },
      nameSelector: {
        label: 'task.form.fields.nameSelector.label',
        placeholder: 'task.form.fields.nameSelector.placeholder',
        inputType: 'Input',
        name: 'nameSelector',
        rules: { required: 'task.form.fields.nameSelector.required' },
      },
      imageSelector: {
        label: 'task.form.fields.imageSelector.label',
        placeholder: 'task.form.fields.imageSelector.placeholder',
        inputType: 'Input',
        name: 'imageSelector',
        rules: { required: 'task.form.fields.imageSelector.required' },
      },
      descriptionSelector: {
        label: 'task.form.fields.descriptionSelector.label',
        placeholder: 'task.form.fields.descriptionSelector.placeholder',
        inputType: 'Input',
        name: 'descriptionSelector',
        rules: { required: 'task.form.fields.descriptionSelector.required' },
      },
      itemUrlSelector: {
        label: 'task.form.fields.itemUrlSelector.label',
        placeholder: 'task.form.fields.itemUrlSelector.placeholder',
        inputType: 'Input',
        name: 'itemUrlSelector',
        rules: { required: 'task.form.fields.itemUrlSelector.required' },
      },
      contentSelector: {
        label: 'task.form.fields.contentSelector.label',
        placeholder: 'task.form.fields.contentSelector.placeholder',
        inputType: 'Input',
        name: 'contentSelector',
        rules: { required: 'task.form.fields.contentSelector.required' },
      },
      removeContentSelector: {
        label: 'task.form.fields.removeContentSelector.label',
        placeholder: 'task.form.fields.removeContentSelector.placeholder',
        inputType: 'Input',
        name: 'removeContentSelector',
        rules: {},
      },
    };
  },
};
