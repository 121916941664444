import { useDisclosure } from '@mantine/hooks';
import { userToken } from '@pkg/constants';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { LoginForm } from '../../src/base/containers/login/LoginForm';

export const Login = () => {
  const router = useRouter();
  const [loading, { close }] = useDisclosure(true);

  useEffect(() => {
    const token = localStorage.getItem(userToken);
    if (token) router.push('/');
    else close();
  }, []);

  return !loading && <LoginForm />;
};
