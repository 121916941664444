import { AdminLayout } from '../../../src/base/components';
import { WidgetEditContainer } from '../../../src/base/containers/widgets/EditContainer';

export const WidgetEdit = () => {
  return (
    <AdminLayout>
      <WidgetEditContainer />
    </AdminLayout>
  );
};
