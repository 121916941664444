import { AdminLayout } from '../../../src/base/components';
import { TaskCreateContainer } from '../../../src/base/containers/tasks/CreateContainer';

export const TaskCreate = () => {
  return (
    <AdminLayout>
      <TaskCreateContainer />
    </AdminLayout>
  );
};
