import { TaskCreationBody, TaskQueryParams, TaskUpdatingBody } from '@models/base';
import { ITaskStatus } from '@pkg/constants';
import { fetchData } from '@pkg/ui';
import { withAuth } from '@pkg/utils';
import urlcat from 'urlcat';

export const TaskApi = () => {
  return {
    async create(data: TaskCreationBody) {
      return await fetchData(withAuth({ endpoint: 'tasks', method: 'POST', body: data }));
    },
    async get(query: TaskQueryParams) {
      return await fetchData(withAuth({ endpoint: 'tasks', query }));
    },
    async getById(id: string) {
      return await fetchData(withAuth({ endpoint: urlcat('tasks/:id', { id }) }));
    },
    async update(id: string, data: TaskUpdatingBody) {
      return await fetchData(withAuth({ endpoint: urlcat('tasks/:id', { id }), method: 'PUT', body: data }));
    },
    async restore(ids: string[]) {
      return await fetchData(withAuth({ endpoint: urlcat('tasks/restore', {}), method: 'PATCH', body: { ids } }));
    },
    async remove(ids: string[]) {
      return await fetchData(withAuth({ endpoint: urlcat('tasks/remove', {}), method: 'PATCH', body: { ids } }));
    },
    async delete(ids: string[]) {
      return await fetchData(withAuth({ endpoint: urlcat('tasks/delete', {}), method: 'DELETE', body: { ids } }));
    },
    async test(data: TaskCreationBody) {
      return await fetchData(withAuth({ endpoint: 'crawler/test', method: 'POST', body: data }));
    },
    async updateStatus(id: string, status: ITaskStatus) {
      return await fetchData(
        withAuth({ endpoint: urlcat('tasks/:id/property/status', { id }), method: 'PATCH', body: { status } })
      );
    },
  };
};
