import { cronJobStatusOptions, CronjobUpdatingFieldModel, timeCronOptions } from '../../types/cronjob';

type CronjobEditModelProps = {};

export const CronjobEditModel = {
  model: (props: CronjobEditModelProps = {}): CronjobUpdatingFieldModel => {
    return {
      time: {
        label: 'cronjob.fields.time.label',
        placeholder: 'cronjob.fields.time.placeholder',
        inputType: 'Dropdown',
        name: 'time',
        options: timeCronOptions,
        rules: { required: 'cronjob.fields.time.required' },
      },
      task: {
        label: 'task.title',
        placeholder: 'cronjob.fields.task.placeholder',
        inputType: 'Dropdown',
        name: 'task',
        options: [],
        rules: { required: 'cronjob.fields.task.required' },
      },
      status: {
        label: 'task.title',
        placeholder: 'cronjob.fields.task.placeholder',
        inputType: 'Dropdown',
        name: 'task',
        options: cronJobStatusOptions,
        rules: { required: 'cronjob.fields.task.required' },
      },
    };
  },
};
