import { AdminLayout } from '../../../src/base/components';
import { EditTaskContainer } from '../../../src/base/containers/tasks/EditContainer';

export const EditTask = () => {
  return (
    <AdminLayout>
      <EditTaskContainer />
    </AdminLayout>
  );
};
