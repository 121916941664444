import { ChangePasswordForm } from './ChangePassword';

export const ProfileContainer = () => {
  return (
    <div>
      <div>Profile page</div>
      <ChangePasswordForm />
    </div>
  );
};
