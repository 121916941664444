import { AdminLayout } from '../../../src/base/components/AdminLayout';
import { WidgetContainer } from '../../../src/base/containers/widgets/Container';

export const Widget = () => {
  return (
    <AdminLayout>
      <WidgetContainer />
    </AdminLayout>
  );
};
