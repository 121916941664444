import { Module } from '@pkg/config';
import { ITaskStatus, ITaskType, TaskType } from '@pkg/constants';
import { DropdownFieldModel, DropdownOptions, InputFieldModel } from '@pkg/ui';

export interface TaskCreationFieldModel {
  type: DropdownFieldModel;
  name: InputFieldModel;
  target: DropdownFieldModel;
  parent: DropdownFieldModel;
  sourceUrl: InputFieldModel;
  listSelector: InputFieldModel;
  itemSelector: InputFieldModel;
  nameSelector: InputFieldModel;
  imageSelector: InputFieldModel;
  descriptionSelector: InputFieldModel;
  itemUrlSelector: InputFieldModel;
  contentSelector: InputFieldModel;
  removeContentSelector: InputFieldModel;
}

export interface TaskUpdatingFieldModel extends TaskCreationFieldModel {}

export const TaskStatusLabel: Record<ITaskStatus, string> = {
  verify: 'task.verify',
  waiting: 'task.waiting',
};

export interface TypeItem extends DropdownOptions {
  value: ITaskType;
}

export interface TargetItem extends DropdownOptions {
  value: Module;
}

export const taskTypeLabel: Record<ITaskType, string> = {
  create: 'task.form.fields.type.create',
  update: 'task.form.fields.type.update',
};

export const taskTypeOptions: TypeItem[] = [
  { label: taskTypeLabel.create, value: TaskType.create },
  { label: taskTypeLabel.update, value: TaskType.update },
];

export const targetOptions: TargetItem[] = [{ label: 'task.form.fields.type.post', value: 'posts' }];

export const TargetItemLabel: Partial<Record<Module, string>> = {
  posts: 'task.form.fields.type.post',
};
