import { AdminLayout } from '../../../src/base/components/AdminLayout';
import { TaskContainer } from '../../../src/base/containers/tasks/Container';

export const Task = () => {
  return (
    <AdminLayout>
      <TaskContainer />
    </AdminLayout>
  );
};
