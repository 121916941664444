import { UserLoginBody } from '@models/base';
import { userToken } from '@pkg/constants';
import { AcnButton, AutoForm, fetchData, useFormModel, useTrans } from '@pkg/ui';
import { cn, showErrorNoti, showSuccessNoti } from '@pkg/utils';
import { useRouter } from 'next/router';
import { FormProvider, useForm } from 'react-hook-form';
import { LoginModel } from './login.model';

export const LoginForm = () => {
  const { t } = useTrans();
  const router = useRouter();

  const formModel = useFormModel(LoginModel.model, {});
  const form = useForm<UserLoginBody>();

  const onSubmit = async (data: UserLoginBody) => {
    const res = await fetchData({ endpoint: 'users/login', body: data });
    if (res.error) {
      if (res.error.statusCode === 429) return showErrorNoti(t('tooManyRequest'));
      return showErrorNoti(t('loginError'));
    }
    showSuccessNoti(t('loginSuccess'));
    localStorage.setItem(userToken, res.data.access_token);
    router.push('/');
  };

  return (
    <div>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className={cn('w-96 p-4 space-y-4')}>
          <AutoForm model={formModel.model} setModel={formModel.setModel} />
          <AcnButton loading={form.formState.isLoading} disabled={form.formState.isLoading} htmlType="submit">
            {t('loginLabel')}
          </AcnButton>
        </form>
      </FormProvider>
    </div>
  );
};
