import { LoginFieldsModel } from '../../types/account/login';

type LoginModelProps = {};

export const LoginModel = {
  model: (props: LoginModelProps = {}): LoginFieldsModel => {
    return {
      username: {
        label: 'loginForm.username.label',
        placeholder: 'loginForm.username.placeholder',
        inputType: 'Input',
        name: 'username',
        rules: { required: 'loginForm.username.required' },
      },
      password: {
        label: 'loginForm.password.label',
        placeholder: 'loginForm.password.placeholder',
        inputType: 'Password',
        name: 'password',
        rules: { required: 'loginForm.password.required' },
      },
    };
  },
};
