import { AdminLayout } from '../../src/base/components';
import { ProfileContainer } from '../../src/base/containers/account/ProfileContainer';

export const Profile = () => {
  return (
    <AdminLayout>
      <ProfileContainer />
    </AdminLayout>
  );
};
