import { AdminLayout } from '../../../src/base/components';
import { WidgetCreateContainer } from '../../../src/base/containers/widgets/CreateContainer';

export const WidgetCreate = () => {
  return (
    <AdminLayout>
      <WidgetCreateContainer />
    </AdminLayout>
  );
};
