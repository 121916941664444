import { AdminLayout } from '../../../src/base/components';
import { CronjobCreateContainer } from '../../../src/base/containers/conjobs/CreateContainer';

export const CronjobCreate = () => {
  return (
    <AdminLayout>
      <CronjobCreateContainer />
    </AdminLayout>
  );
};
