import { Widget, WidgetQueryParams } from '@models/base';
import { BreadcrumbItem, useQueryFilter, useTrans } from '@pkg/ui';
import { formatDate, showErrorNoti, showSuccessNoti } from '@pkg/utils';
import { useQuery } from '@tanstack/react-query';
import { TableColumnsType } from 'antd';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { Toolbar } from '../../components';
import { AdminDataTable } from '../../components/AdminDataTable';
import { getModule } from '../../helpers/utils';
import { WidgetApi } from './api';

export const WidgetContainer = () => {
  const { t } = useTrans();
  const api = WidgetApi();
  const router = useRouter();
  const breadcrumb: BreadcrumbItem[] = [{ title: t('dashboard.title'), href: '/' }, { title: t('widget.title') }];
  const [selected, setSelected] = useState<string[]>([]);
  const { filter, setFilter } = useQueryFilter<WidgetQueryParams>('widgets', { deleted: false });

  const { data: widgets, refetch } = useQuery({
    queryKey: ['widgets', filter],
    queryFn: () => api.get(filter).then((rs) => rs.data || []),
  });

  const onCreate = () => {
    const module = getModule(router);
    router.push(`/${module}/create`);
  };

  const onUpdate = (row: Widget) => {
    const module = getModule(router);
    router.push(`/${module}/${row._id}/edit`);
  };

  const onRestore = async (row?: Widget) => {
    if (row) {
      const res = await api.restore([row._id!]);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('restoreSuccess', { name: row.name }));
    } else {
      const res = await api.restore(selected);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('restoreItemsSuccess', { count: selected.length }));
      setSelected([]);
    }
    refetch();
  };

  const onRemove = async (row?: Widget) => {
    if (row) {
      const res = await api.remove([row._id!]);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('deleteSuccess', { name: row.name }));
    } else {
      const res = await api.remove(selected);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('deleteItemsSuccess', { count: selected.length }));
      setSelected([]);
    }
    refetch();
  };

  const onDelete = async (row?: Widget) => {
    if (row) {
      const res = await api.delete([row._id!]);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('deleteSuccess', { name: row.name }));
    } else {
      const res = await api.delete(selected);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('deleteItemsSuccess', { count: selected.length }));
      setSelected([]);
    }
    refetch();
  };

  const columns: TableColumnsType<Widget> = [
    { title: t('widget.fields.name.label'), dataIndex: 'name' },
    { title: t('dataTable.createdDate'), render: (row: Widget) => formatDate(row.created!) },
    { title: t('dataTable.updatedDate'), render: (row: Widget) => formatDate(row.updated!) },
  ];

  return (
    <div className="space-y-3">
      <Toolbar
        deleted={filter.deleted}
        items={breadcrumb}
        onCreate={onCreate}
        onRestore={onRestore}
        onRemove={onRemove}
        onDelete={onDelete}
        setFilter={setFilter}
        selected={selected}
      />

      <div className="bg-secondary p-4 h-[calc(100vh-178px)] overflow-y-auto">
        <AdminDataTable<Widget>
          data={widgets?.data || []}
          pages={widgets?.pages}
          deleted={filter.deleted}
          columns={columns}
          onEdit={onUpdate}
          onRestore={onRestore}
          onRemove={onRemove}
          onDelete={onDelete}
          onSelected={setSelected}
          rowsSelected={selected}
        />
      </div>
    </div>
  );
};
