import { CronJobStatus, ICronJobStatus } from '@pkg/constants';
import { DropdownFieldModel, DropdownOptions } from '@pkg/ui';

export interface CronjobCreationFieldModel {
  time: DropdownFieldModel;
  task: DropdownFieldModel;
}

export interface CronjobUpdatingFieldModel extends CronjobCreationFieldModel {
  status: DropdownFieldModel;
}

export type StatusOption = {
  label: string;
  value: ICronJobStatus;
};

export const timeCronLabel: Record<string, string> = {
  '1': '5 phút chạy một lần',
  '2': '10 phút chạy một lần',
  '3': '15 phút chạy một lần',
  '4': '20 phút chạy một lần',
  '5': '25 phút chạy một lần',
  '6': '30 phút chạy một lần',
  '7': '1 tiếng chạy một lần',
  '8': '2 tiếng chạy một lần',
  '9': '3 tiếng chạy một lần',
  '10': '4 tiếng chạy một lần',
  '11': '5 tiếng chạy một lần',
  '12': '6 tiếng chạy một lần',
  '13': '7 tiếng chạy một lần',
  '14': '8 tiếng chạy một lần',
  '15': '9 tiếng chạy một lần',
  '16': '10 tiếng chạy một lần',
  '17': '11 tiếng chạy một lần',
  '18': '12 tiếng chạy một lần',
  '19': 'Chạy lúc 0 giờ hàng ngày',
  '20': 'Chạy lúc 1 giờ hàng ngày',
  '21': 'Chạy lúc 2 giờ hàng ngày',
  '22': 'Chạy lúc 3 giờ hàng ngày',
  '23': 'Chạy lúc 4 giờ hàng ngày',
  '24': 'Chạy lúc 5 giờ hàng ngày',
  '25': 'Chạy lúc 6 giờ hàng ngày',
  '26': 'Chạy lúc 7 giờ hàng ngày',
  '27': 'Chạy lúc 8 giờ hàng ngày',
  '28': 'Chạy lúc 9 giờ hàng ngày',
  '29': 'Chạy lúc 10 giờ hàng ngày',
  '30': 'Chạy lúc 11 giờ hàng ngày',
  '31': 'Chạy lúc 12 giờ hàng ngày',
  '32': 'Chạy lúc 13 giờ hàng ngày',
  '33': 'Chạy lúc 14 giờ hàng ngày',
  '34': 'Chạy lúc 15 giờ hàng ngày',
  '35': 'Chạy lúc 16 giờ hàng ngày',
  '36': 'Chạy lúc 17 giờ hàng ngày',
  '37': 'Chạy lúc 18 giờ hàng ngày',
  '38': 'Chạy lúc 19 giờ hàng ngày',
  '39': 'Chạy lúc 20 giờ hàng ngày',
  '40': 'Chạy lúc 21 giờ hàng ngày',
  '41': 'Chạy lúc 22 giờ hàng ngày',
  '42': 'Chạy lúc 23 giờ hàng ngày',
};

export const timeCronOptions: DropdownOptions[] = Object.keys(timeCronLabel).map((key) => {
  return { value: key, label: timeCronLabel[key]! };
});

export const cronJobStatus: Record<ICronJobStatus, string> = {
  start: 'cronjob.start',
  stop: 'cronjob.stop',
};

export const cronJobStatusOptions: StatusOption[] = [
  { label: 'cronjob.start', value: CronJobStatus.start },
  { label: 'cronjob.stop', value: CronJobStatus.stop },
];
