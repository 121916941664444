import { WidgetCreationBody, WidgetQueryParams, WidgetUpdatingBody } from '@models/base';
import { fetchData } from '@pkg/ui';
import { withAuth } from '@pkg/utils';
import urlcat from 'urlcat';

export const WidgetApi = () => {
  return {
    async create(data: WidgetCreationBody) {
      return await fetchData(withAuth({ endpoint: 'widgets', method: 'POST', body: data }));
    },
    async get(query: WidgetQueryParams) {
      return await fetchData(withAuth({ endpoint: 'widgets', query }));
    },
    async getById(id: string) {
      return await fetchData(withAuth({ endpoint: urlcat('widgets/:id', { id }) }));
    },
    async update(id: string, data: WidgetUpdatingBody) {
      return await fetchData(withAuth({ endpoint: urlcat('widgets/:id', { id }), method: 'PUT', body: data }));
    },
    async restore(ids: string[]) {
      return await fetchData(withAuth({ endpoint: urlcat('widgets/restore', {}), method: 'PATCH', body: { ids } }));
    },
    async remove(ids: string[]) {
      return await fetchData(withAuth({ endpoint: urlcat('widgets/remove', {}), method: 'PATCH', body: { ids } }));
    },
    async delete(ids: string[]) {
      return await fetchData(withAuth({ endpoint: urlcat('widgets/delete', {}), method: 'DELETE', body: { ids } }));
    },
  };
};
