import { CronjobCreationBody, CronjobQueryParams, CronjobUpdatingBody } from '@models/base';
import { ICronJobStatus } from '@pkg/constants';
import { fetchData } from '@pkg/ui';
import { withAuth } from '@pkg/utils';
import urlcat from 'urlcat';

export const CronjobApi = () => {
  return {
    async create(data: CronjobCreationBody) {
      return await fetchData(withAuth({ endpoint: 'cronjobs', method: 'POST', body: data }));
    },
    async get(query: CronjobQueryParams) {
      return await fetchData(withAuth({ endpoint: 'cronjobs', query }));
    },
    async getById(id: string) {
      return await fetchData(withAuth({ endpoint: urlcat('cronjobs/:id', { id }) }));
    },
    async update(id: string, data: CronjobUpdatingBody) {
      return await fetchData(withAuth({ endpoint: urlcat('cronjobs/:id', { id }), method: 'PUT', body: data }));
    },
    async restore(ids: string[]) {
      return await fetchData(withAuth({ endpoint: urlcat('cronjobs/restore', {}), method: 'PATCH', body: { ids } }));
    },
    async remove(ids: string[]) {
      return await fetchData(withAuth({ endpoint: urlcat('cronjobs/remove', {}), method: 'PATCH', body: { ids } }));
    },
    async delete(ids: string[]) {
      return await fetchData(withAuth({ endpoint: urlcat('cronjobs/delete', {}), method: 'DELETE', body: { ids } }));
    },
    async updateStatus(id: string, status: ICronJobStatus) {
      return await fetchData(
        withAuth({ endpoint: urlcat('cronjobs/:id/property/status', { id }), method: 'PATCH', body: { status } })
      );
    },
  };
};
