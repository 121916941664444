import { AdminLayout } from '../../../src/base/components';
import { CronjobEditContainer } from '../../../src/base/containers/conjobs/EditContainer';

export const CronjobEdit = () => {
  return (
    <AdminLayout>
      <CronjobEditContainer />
    </AdminLayout>
  );
};
