import { blockWidgetOptions } from '@pkg/config';
import { WidgetCreationFieldModel } from '../../types/widget';

type WidgetCreationModelProps = {};

export const WidgetCreationModel = {
  model: (props: WidgetCreationModelProps = {}): WidgetCreationFieldModel => {
    return {
      name: {
        label: 'widget.fields.name.label',
        placeholder: 'widget.fields.name.placeholder',
        inputType: 'Input',
        name: 'name',
        rules: { required: 'widget.fields.name.required' },
      },
      key: {
        label: 'widget.fields.key.label',
        placeholder: 'widget.fields.key.placeholder',
        inputType: 'Input',
        name: 'key',
        rules: { required: 'widget.fields.key.required' },
      },
      position: {
        label: 'widget.fields.position.label',
        placeholder: 'widget.fields.position.placeholder',
        inputType: 'Dropdown',
        name: 'position',
        options: blockWidgetOptions,
        hiddenLabel: true,
        type: 'multiple',
        rules: {},
      },
      items: {
        label: 'widget.fields.items.label',
        inputType: 'Input',
        name: 'items',
        value: JSON.stringify([]),
        rules: {},
      },
    };
  },
};
