import { Widget, WidgetUpdatingBody } from '@models/base';
import {
  AcnButton,
  AcnCard,
  AutoForm,
  BreadcrumbItem,
  baseButton,
  baseScrollbar,
  getModelValues,
  useFormModel,
  useTrans,
} from '@pkg/ui';
import { cn, showErrorNoti, showSuccessNoti } from '@pkg/utils';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Toolbar } from '../../components';
import { getModule } from '../../helpers/utils';
import { WidgetApi } from './api';
import { WidgetEditModel } from './edit.model';
import { SelectedItems } from './SelectedItems';

export const WidgetEditContainer = () => {
  const { t } = useTrans();
  const router = useRouter();
  const api = WidgetApi();
  const id = router.query.id as string;

  const formModel = useFormModel(WidgetEditModel.model, {});

  const form = useForm<WidgetUpdatingBody>({
    mode: 'all',
    defaultValues: getModelValues(WidgetEditModel.model),
  });

  const { data: details } = useQuery<Widget>({
    queryKey: ['widget', id],
    queryFn: () => api.getById(id).then((rs) => rs.data),
    enabled: !!id,
  });

  useEffect(() => {
    if (details) {
      form.reset({ ...details });
    }
  }, [details]);

  const breadcrumb: BreadcrumbItem[] = [
    { title: t('dashboard.title'), href: '/' },
    { title: t('widget.title'), href: '/' + getModule(router) },
    { title: t('widget.editTitle') },
  ];

  const onUpdate = async (data: WidgetUpdatingBody) => {
    const res = await api.update(id, data);
    if (!res.data) return showErrorNoti(res.error.message);
    showSuccessNoti(t('updateSuccess'));
    router.push('/' + getModule(router));
  };

  return (
    <div className="space-y-3">
      <Toolbar items={breadcrumb} />
      <div className={cn('flex gap-3 h-[calc(100vh-175px)]', baseScrollbar)}>
        <FormProvider {...form}>
          <div className={cn('flex-1 space-y-3')}>
            <AcnCard>
              <AcnCard.Title>{t('details')}</AcnCard.Title>
              <AcnCard.Content className="min-h-[calc(100vh-221px)]">
                <div className="space-y-4">
                  <AutoForm
                    model={formModel.model}
                    setModel={formModel.setModel}
                    excludeFields={['items', 'position']}
                  />
                  <SelectedItems
                    formModel={formModel}
                    value={form.getValues().items}
                    onChange={(value) => form.setValue('items', value)}
                  />
                </div>
              </AcnCard.Content>
            </AcnCard>
          </div>

          <div className="w-[400px] space-y-3">
            <AcnCard>
              <AcnCard.Title>{t('publish')}</AcnCard.Title>
              <AcnCard.Content className="space-x-4">
                <AcnButton className={cn(baseButton, '!bg-blue-500 !text-white')} onClick={form.handleSubmit(onUpdate)}>
                  {t('editBtn')}
                </AcnButton>
              </AcnCard.Content>
            </AcnCard>

            <AcnCard>
              <AcnCard.Title>{t('blockWidget.title')}</AcnCard.Title>
              <AcnCard.Content>
                <AutoForm model={formModel.model} setModel={formModel.setModel} fields={['position']} />
              </AcnCard.Content>
            </AcnCard>
          </div>
        </FormProvider>
      </div>
    </div>
  );
};
