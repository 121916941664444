import { ChangePasswordFieldsModel } from '../../types/account/changePassword';

type ChangePasswordProps = {};

export const ChangePassword = {
  model: (props: ChangePasswordProps = {}): ChangePasswordFieldsModel => {
    return {
      password: {
        label: 'changePasswordForm.password.label',
        placeholder: 'changePasswordForm.password.placeholder',
        inputType: 'Password',
        name: 'password',
        rules: { required: 'changePasswordForm.password.required' },
      },
      newPassword: {
        label: 'changePasswordForm.newPassword.label',
        placeholder: 'changePasswordForm.newPassword.placeholder',
        inputType: 'Password',
        name: 'newPassword',
        rules: {
          required: 'changePasswordForm.newPassword.required',
          validate: (value, formValues) => {
            return value === formValues.password ? 'changePasswordForm.newPassword.match' : undefined;
          },
        },
      },
      confirmPassword: {
        label: 'changePasswordForm.confirmPassword.label',
        placeholder: 'changePasswordForm.confirmPassword.placeholder',
        inputType: 'Password',
        name: 'confirmPassword',
        rules: {
          required: 'changePasswordForm.confirmPassword.required',
          validate: (value, formValues) => {
            return value !== formValues.newPassword ? 'changePasswordForm.confirmPassword.notMatch' : undefined;
          },
        },
      },
    };
  },
};
