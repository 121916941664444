import { DeleteOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import { useDisclosure } from '@mantine/hooks';
import { Post } from '@models/news';
import {
  AcnButton,
  AcnCard,
  AcnNestable,
  AcnSegmented,
  AcnTooltip,
  AutoFormModelResult,
  baseBorderColor,
  baseScrollbar,
  NestableRenderItem,
  useTrans,
} from '@pkg/ui';
import { cn, getUuidv4, showErrorNoti } from '@pkg/utils';
import { useQuery } from '@tanstack/react-query';
import { Empty } from 'antd';
import { find, isEmpty, pick } from 'lodash';
import { useEffect, useState } from 'react';
import { PostApi } from '../../../news';
import { SearchBox } from '../../components';
import { WidgetCreationFieldModel } from '../../types/widget';

type SelectedItemsProps = {
  formModel: AutoFormModelResult<WidgetCreationFieldModel>;
  value: string | undefined;
  onChange: (value: string) => void;
};

export const SelectedItems = ({ formModel, value, onChange: setFieldValue }: SelectedItemsProps) => {
  const { t } = useTrans();
  const postApi = PostApi();
  const [keyword, setKeyword] = useState<string | undefined>();
  const [dragOver, { open: startDrag, close: endDrag }] = useDisclosure(false);
  const [selectedData, setSelectedData] = useState<any[]>(value ? JSON.parse(value) || [] : []);
  const [moduleName, setModuleName] = useState<string>('posts');

  const { data: posts = [] } = useQuery<Post[]>({
    queryKey: ['widget-posts', keyword],
    queryFn: async () =>
      await postApi.get({ keyword, limit: 10, active: true, deleted: false }).then((res) => res.data?.data || []),
  });

  useEffect(() => {
    if (value) {
      setSelectedData(JSON.parse(value));
    }
  }, [value]);

  const onChange = (data: any) => {
    setSelectedData(data.items);
    setFieldValue(JSON.stringify(data.items));
  };

  const onDelete = (item: any) => {
    const items = selectedData.filter((r) => r.id !== item.id);
    setSelectedData(items);
    setFieldValue(JSON.stringify(items));
  };

  const onDrop = (e: any) => {
    let elmId = e.dataTransfer.getData('dragId');
    if (elmId) {
      let item: any = {};
      const { id, type } = JSON.parse(elmId);
      if (selectedData.find((r) => r.id === id)) return showErrorNoti(t('widget.message.itemExisted'));

      if (type === 'posts') {
        item = { type, ...(find(posts, { _id: id }) || {}) };
      }

      item.id = item._id || getUuidv4();
      item = pick(item, ['id', 'title', 'slug', 'type', 'image', 'description']);

      const items = [...selectedData, item];
      setSelectedData(items);
      setFieldValue(JSON.stringify(items));
    }
  };

  const onDragStart = (e: any, type: string) => {
    e.dataTransfer.setData('dragId', JSON.stringify({ id: e.target.id, type: type }));
  };

  const onDragEnd = () => {
    endDrag();
  };

  const onDragOver = (e: any) => {
    e.preventDefault();
    startDrag();
  };

  const disableDrop = () => {
    return false;
  };

  const renderPost = () => {
    return (
      <AcnCard withBorder className="rounded-t-md">
        <AcnCard.Content className="px-2 py-3">
          <div className="space-y-2">
            <SearchBox keyword={keyword} onSearch={(keyword) => setKeyword(keyword)} className="w-full" />
            {!isEmpty(posts) ? (
              posts.map((post) => {
                return (
                  <div
                    id={post._id}
                    key={post._id}
                    draggable={true}
                    onDrop={disableDrop}
                    onDragEnd={onDragEnd}
                    onDragStart={(e) => onDragStart(e, 'posts')}
                    className={cn(
                      'drag-item p-1 rounded-md cursor-grab line-clamp-1 border border-solid',
                      baseBorderColor
                    )}
                  >
                    {post.title}
                  </div>
                );
              })
            ) : (
              <Empty />
            )}
          </div>
        </AcnCard.Content>
      </AcnCard>
    );
  };

  const renderItem = ({ item }: NestableRenderItem) => {
    return (
      <div className="flex" key={item.id}>
        <span className="leading-7">{item.title}</span>
        <div className="flex-grow" />
        <div className="flex items-center gap-4">
          <AcnTooltip title={t('dataTable.remove')}>
            <AcnButton
              size="small"
              className="!bg-red-600 !text-white !border-0"
              icon={<DeleteOutlined onClick={() => onDelete(item)} />}
            />
          </AcnTooltip>
        </div>
      </div>
    );
  };

  return (
    <div className="relative space-y-2">
      <div>{t(formModel.model?.items.label!)}</div>
      <div className={cn('md:flex md:gap-4 h-[500px]')}>
        <div className="space-y-2 w-72">
          <div
            className={cn(
              'py-[5px] px-2 border-solid border rounded-md bg-gray-100 dark:bg-secondary outline-none',
              baseBorderColor
            )}
          >
            <AcnSegmented
              className="bg-transparent border-stone-300"
              options={[{ label: t('post.title'), value: 'posts' }]}
              onChange={(value) => setModuleName(value.toString())}
            />
          </div>

          {moduleName === 'posts' && renderPost()}
        </div>
        <div className={cn('border border-solid flex-auto', baseBorderColor, baseScrollbar)}>
          <div className="relative p-2 space-y-2">
            <div
              id="drop-block"
              className={cn('p-4 text-center border border-dashed text-base sticky top-0 z-10 bg-secondary', {
                '!bg-sky-300 border border-solid': dragOver,
              })}
              onDrop={onDrop}
              onDragOver={onDragOver}
            >
              <FullscreenExitOutlined /> {t('dragDrop.dropHere')}
            </div>
            <AcnNestable items={selectedData} maxDepth={1} renderItem={renderItem} onChange={onChange} />
          </div>
        </div>
      </div>
    </div>
  );
};
